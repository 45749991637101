import { Controller } from '@hotwired/stimulus';
import { iframeResize } from 'iframe-resizer';

export default class extends Controller {
    connect () {
        this.$resizer = iframeResize({}, this.element);
    }

    disconnect () {
        if (this.$resizer) {
            this.$resizer.removeListeners();
            this.$resizer = null;
        }
    }
}
