import Lightbox from 'stimulus-lightbox'
import lgVideo from 'lightgallery/plugins/video'

import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-video.css'

export default class extends Lightbox {
    get defaultOptions() {
        return {
            plugins: [lgVideo],
            // selector: '[data-lightbox]',
            download: false,
            enableDrag: true,
            youTubePlayerParams: {
                modestbranding : 1,
                showinfo : 0,
                rel: 0,
                controls : 1
            }
        }
    }
}
