import { Controller } from '@hotwired/stimulus';

function request (form, body, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', form.action, true);
    xhr.setRequestHeader('Accept', 'text/html');
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('X-Contao-Ajax-Form', form.querySelector('[name="FORM_SUBMIT"]').value);

    form.ariaBusy = 'true';
    form.dataset.ajaxForm = 'loading';

    xhr.onload = () => {
        form.dispatchEvent(new CustomEvent('ajax-form-onload', {
            bubbles: true,
            detail: { body, form, xhr },
        }));

        form.ariaBusy = 'false';
        form.dataset.ajaxForm = '';

        callback(xhr);
    };

    xhr.send(body || null)
}

export default class extends Controller {
    connect () {
        this.widgets = this.element.querySelectorAll('.widget');
        this.submit = this.element.querySelector('button[type="submit"]');

        this.widgets.forEach((widget) => {
            let input;

            if ((input = widget.querySelector('select'))) {
                this.handleSelect(widget, input);
                return;
            }

            if ((input = widget.querySelector('input, textarea'))) {
                this.handleTextInput(widget, input)
            }
        });
    }

    handleSelect (widget, select) {
        const input = document.createElement('span');
        input.className = 'input';
        select.before(input);

        select.addEventListener('focus', () => widget.classList.add('focus'));
        select.addEventListener('blur', () => widget.classList.remove('focus'));
        select.addEventListener('change', () => this.selectChanged(widget, select, input));
        this.selectChanged(widget, select, input)
    }

    handleTextInput (widget, input) {
        input.addEventListener('focus', () => widget.classList.add('focus'));
        input.addEventListener('blur', () => widget.classList.remove('focus') && this.inputChanged(widget, input));
        input.addEventListener('input', () => this.inputChanged(widget, input));

        this.inputChanged(widget, input)
    }

    selectChanged (widget, select, input) {
        input.innerText = select.value ? select.querySelector('option:checked').innerText : '';

        this.inputChanged(widget, select);
    }

    inputChanged (widget, input) {
        if (!input.value && !input.validity.badInput) {
            widget.classList.add('empty');
            widget.classList.remove('valid');
        } else {
            widget.classList.remove('empty');
            widget.classList[input.validity.valid ? 'add' : 'remove']('valid');
        }

        this.checkValidity();
    }

    checkValidity () {
        const invalid = this.element.querySelector('input:invalid');

        this.submit.classList[invalid ? 'add' : 'remove']('disabled');
        this.element.classList[invalid ? 'add' : 'remove']('invalid');
    }

    onsubmit (event) {
        event.preventDefault();

        const invalid = this.element.querySelector('input:invalid');

        if (invalid) {
            invalid.focus();
            event.stopPropagation();
            return;
        }

        const formData = new FormData(this.element);

        // Send the triggered button data as well
        if (event.submitter) {
            formData.append(event.submitter.name, event.submitter.value);
        }

        request(this.element, formData, xhr => {
            const location = xhr.getResponseHeader('X-Ajax-Location');

            // Handle the redirect header
            if (location) {
                window.location.href = location;
                return;
            }

            const template = document.createElement('template');
            template.innerHTML = xhr.responseText.trim();

            const newForm = template.content.firstElementChild;
            this.element.replaceWith(newForm);
        });
    }
}
