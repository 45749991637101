import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['confirm'];

    static values = {
        content: String,
        message: String,
        duration: { type: Number, default: 2000 },
    };

    copy (e) {
        e.preventDefault();
        this.originalContent = this.element.innerHTML;
        navigator.clipboard && navigator.clipboard.writeText(this.contentValue).then(() => this.copied());
    }

    copied () {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.element.innerHTML = this.messageValue;

        this.timeout = setTimeout(() => {
            this.element.innerHTML = this.originalContent;
        }, this.durationValue);
    }
}
