import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['class', 'ariaExpanded'];
    static classes = ['toggle'];

    toggle () {
        const isOpen = this.getTarget().classList.contains(this.toggleClass);

        if (isOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    open () {
        this.dispatch('open');
        this.getTarget().classList.add(this.toggleClass);

        if (this.hasAriaExpandedTarget) {
            this.ariaExpandedTarget.setAttribute('aria-expanded', 'true');
        }
    }

    close () {
        this.dispatch('close');
        this.getTarget().classList.remove(this.toggleClass);

        if (this.hasAriaExpandedTarget) {
            this.ariaExpandedTarget.setAttribute('aria-expanded', 'false');
        }
    }

    getTarget () {
        return this.hasClassTarget ? this.classTarget : document.body;
    }
}
