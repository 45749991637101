import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        name: String,
        version: { type: String, default: '1' }
    }

    connect () {
        if (window.localStorage.getItem(`popup/${this.nameValue}`) === this.versionValue) {
            this.close();
        }
    }

    close () {
        window.localStorage.setItem(`popup/${this.nameValue}`, this.versionValue)
        this.element.remove();
    }
}
