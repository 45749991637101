import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        loop: { type: Boolean, default: true },
    };

    connect () {
        const currentSource = this.element.querySelector('img').currentSrc;

        if (currentSource.substring(currentSource.length - 4) === '.mp4') {
            return;
        }

        const picture = this.element.querySelector('picture');
        const video = document.createElement('video');

        picture.querySelectorAll('source').forEach((source) => {
            source.src = source.srcset;
            delete source.srcset;
        });

        video.append(...picture.childNodes);
        picture.replaceWith(video);
        video.loop = this.loopValue;
        video.muted = true;
        video.autoplay = true;
        video.playsInline = true;
        video.play();
    }
}
