import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['dialog'];
    static classes = ['toggle'];

    connect () {
        this.dialogTarget.style.display = 'none';
    }

    open () {
        this.dialogTarget.style.removeProperty('display');
        this.dialogTarget.showModal();
        setTimeout(() => {
            this.dialogTarget.classList.add(this.toggleClass);
        }, 10);
    }

    close () {
        this.dialogTarget.addEventListener('transitionend', () => {
            this.dialogTarget.close();
            this.dialogTarget.style.display = 'none';
        }, { once: true });

        this.dialogTarget.classList.remove(this.toggleClass);
    }
}
