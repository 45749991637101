
import Handorgel from 'handorgel';

document.addEventListener('DOMContentLoaded', () => {
    const headerElements = document.querySelectorAll('.ce_accordion > .toggler');
    const contentElements = [];

    if (!headerElements) {
        return;
    }

    headerElements.forEach((toggler) => {
        const button = document.createElement('button');
        button.type = 'button';
        button.append(...toggler.childNodes);
        toggler.appendChild(button);

        if ('open' in toggler.dataset) {
            toggler.parentNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        toggler.parentNode.classList.add('handorgel');
        contentElements.push(toggler.nextElementSibling);
    });

    new Handorgel(document.body, {
        headerElements,
        contentElements,
        headerOpenClass: 'open',
        contentOpenClass: 'open',
        headerOpenedClass: 'active',
        contentOpenedClass: 'active',
        headerFocusClass: 'focus',
        contentFocusClass: 'focus',
        headerDisabledClass: 'disabled',
        contentDisabledClass: 'disabled',
        initialOpenTransition: false,
    });
});
