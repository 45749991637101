import { Controller } from '@hotwired/stimulus';
import { tns as TinySlider } from 'tiny-slider/src/tiny-slider.module';

export default class extends Controller {
    static targets = ['container', 'counter', 'navigation'];

    static values = {
        mode: { type: String, default: 'carousel' },
        disabledFrom: { type: Number, default: 0 },
        controls: { type: Boolean, default: false },
        navigation: { type: Boolean, default: true },
        speed: { type: Number, default: 1000 },
        autoplay: { type: Boolean, default: false },
    }

    connect () {
        if (!this.hasContainerTarget) {
            return;
        }

        const responsive = {};

        if (this.disabledFromValue > 0) {
            responsive[this.disabledFromValue] = {
                disable: true
            };
        }

        this.slider = TinySlider({
            container: this.containerTarget,
            mode: this.modeValue,
            controls: this.controlsValue,
            controlsPosition: 'bottom',
            nav: this.navigationValue,
            navContainer: this.hasNavigationTarget ? this.navigationTarget : false,
            navPosition: 'bottom',
            navAsThumbnails: this.hasNavigationTarget,
            speed: this.speedValue,
            autoplay: this.autoplayValue,
            autoplayButtonOutput: false,
            loop: false,
            autoHeight: false,
            responsive,
            onInit: this.updateNextControl.bind(this),
        });

        this.slider.events.on('indexChanged', this.updateNextControl.bind(this));
    }

    disconnect () {
        if (this.slider) {
            this.slider.destroy();
        }
    }

    updateNextControl (info) {
        if (!this.hasCounterTarget) {
            return;
        }

        this.counterTarget.innerHTML = `#${ info.index + 1 }/${ info.slideCount }`;
    }
}
